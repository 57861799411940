<template>
  <div class="promoter-vendor-dashboard">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="content-path">
          <breadcrumbs :crumbs="breadcrumbs" />
          <div class="page-tabs">
            <tab-tote
              :tabs="navTabs"
              :value="navTabSelected"
              v-on:click="onClickNavTab"
            />
          </div>
          <hr class="divider" />
        </div>
        <router-view :url="url" @set-title="title = arguments[0]" />
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import TabTote from "@/components/TabTote.vue";

export default {
  name: "promoters",
  components: {
    Breadcrumbs,
    TabTote
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Promoter Dashboard"
    };
  },
  data() {
    return {
      breadcrumbs: [
        ["Dashboard", "/dashboard"],
        ["Promoter Dashboard", null]
      ],
      navTabs: [
        "Promoter List",
        "Commissions / Collections",
        "Financials",
        "Rankings"
      ],
      title: ""
    };
  },
  computed: {
    navTabSelected() {
      const path = this.$route.path;

      if (path.endsWith("manage")) {
        return 0;
      } else if (path.endsWith("commissions")) {
        return 1;
      } else if (path.endsWith("financials")) {
        return 2;
      } else if (path.endsWith("rankings")) {
        return 3;
      }

      return 0;
    }
  },
  methods: {
    onClickNavTab(index) {
      const path = this.$route.path;

      if (index === 0 && !path.endsWith("manage")) {
        this.$router.push(`/promoters/${this.url}/manage`);
      } else if (index === 1 && !path.endsWith("commissions")) {
        this.$router.push(`/promoters/${this.url}/commissions`);
      } else if (index === 2 && !path.endsWith("financials")) {
        this.$router.push(`/promoters/${this.url}/financials`);
      } else if (index === 3 && !path.endsWith("rankings")) {
        this.$router.push(`/promoters/${this.url}/rankings`);
      }
    }
  },
  created() {
    this.$store.commit("setTitle", "Promoter Dashboard");
    this.$store.commit(
      "setSubtitle",
      "Promoters, Vendors, Affiliates & Influencers"
    );
    this.$store.commit(
      "setHelpVideoSrc",
      "https://youtube.com/embed/Cph4D4rXMVg"
    );
  }
};
</script>

<style lang="less">
#Content {
  transition: height 0.5s;

  hr {
    max-width: 1100px;
    border: rgba(91, 91, 91, 0.5) solid 0.5px;
    margin-bottom: 25px;
  }

  .content-inner {
    .content-path {
      padding-bottom: 0;

      hr {
        margin: 25px 0;
      }

      .buttons {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;

        @media screen and (max-width: 555px) {
          justify-content: center;
        }
      }
    }
    //end creation-path

    .page-tabs {
      padding: 20px 0;
    }

    h2 {
      text-align: center;
    }
  }
  // end form-inner
}
</style>
