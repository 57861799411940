<template>
  <div class="financials">
    <add-payment-modal
      ref="addPaymentModal"
      :event="url"
      v-on:added="onAddedPayment"
    />
    <ViewCommissionModal ref="viewCommissionModal" />
    <FormulateForm
      name="commissions"
      class="row commission-form"
      @submit="handleSubmit"
      #default="{isLoading}"
      autocomplete="false"
    >
      <div class="col half">
        <h3>Commission Rates</h3>
        <div class="commissions">
          <div class="header">
            <span>Ticket / Product Price</span>
            <span>Commission</span>
          </div>
          <div
            v-for="(commission, index) in formValues.rates"
            class="commission"
            :key="index"
          >
            <div class="range">
              {{ commission.min }} -
              {{ commission.max }}
            </div>
            <div class="value">
              <FormulateInput
                type="money"
                ignored
                small
                placeholder="Enter Amount"
                :disabled="commission.disabled"
                v-model="commission.value"
                autocomplete="false"
              />
            </div>
          </div>
          <FormulateForm
            v-if="showAddAnotherForm"
            name="add-another-rate"
            ref="addAnotherForm"
            class="add-another-form"
            v-model="addAnotherFormValues"
            @submit="handleAddAnotherSubmit"
            autocomplete="false"
          >
            <div class="min">
              <FormulateInput
                type="money"
                name="min"
                small
                placeholder="Min"
                validation="required|overlap"
                :validation-rules="{ overlap: validateOverlap }"
                :validation-messages="{ overlap: 'Ranges cannot overlap.' }"
                autocomplete="false"
              />
            </div>
            <div class="max">
              <FormulateInput
                type="money"
                name="max"
                small
                placeholder="Min"
                validation="required|overlap|greater"
                :validation-rules="{
                  overlap: validateOverlap,
                  greater: validateGreater
                }"
                :validation-messages="{
                  overlap: 'Ranges cannot overlap.',
                  greater: 'Max must be greater than min.'
                }"
                autocomplete="false"
              />
            </div>
            <div class="value">
              <FormulateInput
                type="money"
                name="value"
                small
                placeholder="Enter Amount"
                validation="required"
                autocomplete="false"
              />
            </div>
          </FormulateForm>
          <div class="add-another">
            <TinyButton @click="submitAddAnother">
              + Add Another
            </TinyButton>
          </div>
        </div>
      </div>
      <div class="col half">
        <h3>Promoters</h3>
        <div v-if="promoters" class="promoters">
          <FormulateInput
            type="multiSelect"
            placeholder="Select Promoters"
            ignored
            :options="promotersForCheckboxes"
            v-model="formValues.promoters"
            autocomplete="false"
          />
          <diyobo-button
            small
            class="select-all"
            type="primary"
            :disabled="editingIndex > -1"
            :txt="allPromotersSelected ? 'Deselect All' : 'Select All'"
            @click="onSelectAllPromoters"
          />
        </div>
        <div v-else>
          <Loader />
        </div>
      </div>
      <div class="save-changes">
        <FormulateInput
          type="submit"
          name="Save Changes"
          :loading="isLoading"
        />
      </div>
    </FormulateForm>
    <DataTable
      v-if="editingIndex > -1"
      title="Payments"
      endpoint="/promoters/collections/:event"
      :args="{ event: url }"
      :params="{ pv: editingItem._id }"
      ref="table"
      :headers="headers[1]"
      :custom-columns="['confirmed']"
      :showSearch="false"
    >
      <template v-slot:[`item.confirmed`]="{ item }">
        <div style="display: flex; gap: 4px">
          <span v-if="item.confirmed">
            CONFIRMED
          </span>
          <span v-else>PENDING</span>
        </div>
      </template>
    </DataTable>
    <div class="divider"></div>
    <DataTable
      title="Commission Chart"
      subtitle="NOTE: Once you set a commission rate, you cannot edit it due to financial tables."
      endpoint="/promoters/commissions/:event"
      :args="{ event: url }"
      ref="table"
      :headers="headers[0]"
      :custom-columns="['rates', 'options']"
    >
      <template v-slot:[`item.rates`]="{ item }">
        <a href="#" @click.prevent="onViewCommissions(item)">View</a>
      </template>
      <template v-slot:[`item.options`]="{ item, index }">
        <div style="display: flex; gap: 4px">
          <TinyButton @click="onRecordPayment(item)">
            Record Payment
          </TinyButton>
          <TinyButton @click="onDetails(item, index)">Details</TinyButton>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import AddPaymentModal from "../../components/modals/AddPaymentModal.vue";
import ViewCommissionModal from "../../components/modals/ViewCommissionModal.vue";
import DataTable from "@/components/DataTable.vue";
import Loader from "@/components/Loader.vue";
import TinyButton from "@/components/TinyButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMoneyBill,
  faLink,
  faHandHoldingUsd,
  faTicketAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faMoneyBill, faLink, faHandHoldingUsd, faTicketAlt);

export default {
  name: "promoter-financials",
  components: {
    DiyoboInput,
    DiyoboButton,
    DataTable,
    TinyButton,
    AddPaymentModal,
    ViewCommissionModal,
    Loader,
    FontAwesomeIcon
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Promoter Financials"
    };
  },
  data() {
    return {
      headers: [
        [
          { text: "Promoter", value: "name" },
          { text: "Commission Rates", value: "rates", sortable: false },
          {
            text: "Commission Owing",
            value: "owing",
            format: "currency",
            sortable: false
          },
          { text: "Options", value: "options", sortable: false }
        ],
        [
          { text: "Payment Date", value: "collection_date", format: "date" },
          {
            text: "Amount",
            value: "amount",
            sortable: false,
            format: "currency"
          },
          {
            text: "Paid By",
            value: "paid_by",
            sortable: false
          },
          {
            text: "Source",
            value: "source",
            sortable: false
          },
          {
            text: "Notes",
            value: "notes",
            sortable: false
          },
          { text: "Confirmation", value: "confirmed", sortable: false }
        ]
      ],
      editingItem: {},
      editingIndex: -1,
      showAddAnotherForm: false,
      promoters: null,
      formValues: {
        rates: [
          { min: "0.00", max: "4.99", value: "" },
          { min: "5.00", max: "9.99", value: "" },
          { min: "10.00", max: "19.99", value: "" },
          { min: "20.00", max: "29.99", value: "" },
          { min: "30.00", max: "39.99", value: "" },
          { min: "40.00", max: "49.99", value: "" }
        ],
        promoters: []
      },
      addAnotherFormValues: {
        min: "",
        max: "",
        value: ""
      }
    };
  },
  computed: {
    promotersForCheckboxes() {
      const disabledPromoters = this.promoters
        .filter(p => p.commission_rates && p.commission_rates.length > 0)
        .map(p => p.id);

      console.log(disabledPromoters);

      return this.promoters
        ? this.promoters.map(p => ({
            label: p.name + " (" + p.email + ")",
            value: p.id,
            disabled: disabledPromoters.includes(p.id) || this.editingIndex > -1
          }))
        : [];
    },
    allPromotersSelected() {
      const promoterIDs = this.promotersForCheckboxes
        .filter(p => !p.disabled)
        .map(p => p.value);
      return this.formValues.promoters.length === promoterIDs.length;
    }
  },
  methods: {
    validateOverlap({ value }) {
      return !this.formValues.rates.some(
        r => Number(r.min) <= Number(value) && Number(r.max) >= Number(value)
      );
    },
    validateGreater({ value }) {
      return Number(value) > Number(this.addAnotherFormValues.min);
    },
    onAddedPayment(payment) {
      this.$toast("Payment recorded successfully.", { type: "success" });
      this.$refs.table.refetch();
    },
    onViewCommissions(promoter) {
      console.log(promoter);
      this.$refs.viewCommissionModal.open(promoter);
    },
    onRecordPayment(promoter) {
      console.log(promoter);
      this.$refs.addPaymentModal.open(promoter);
    },
    onDetails(promoter, index) {
      console.log(promoter);
      this.editingItem = promoter;
      this.editingIndex = index;
      this.formValues.promoters = [promoter._id];
      this.formValues.rates = promoter.commission_rates.map(r => ({
        ...r,
        value: Number(r.value).toFixed(2),
        disabled: true
      }));

      console.log(this.formValues.rates);

      this.$el
        .querySelector(".commission-form")
        .scrollIntoView({ behavior: "smooth" });
    },
    onSelectAllPromoters() {
      const selected = this.allPromotersSelected;
      console.log("allpromoters", this.promotersForCheckboxes);

      if (selected) {
        this.formValues.promoters = [];
      } else {
        this.formValues.promoters = this.promotersForCheckboxes
          .filter(p => !p.disabled)
          .map(p => p.value);
      }
    },
    handlePromoterCheck(promoter, value) {
      const index = this.formValues.promoters.indexOf(promoter);
      if (value) {
        if (index === -1) {
          this.formValues.promoters.push(promoter);
        }
      } else {
        this.formValues.promoters.splice(index, 1);
      }
    },
    async handleSubmit() {
      let addAnotherFormValid = false;
      if (this.$refs.addAnotherForm) {
        addAnotherFormValid = !(await this.$refs.addAnotherForm.hasValidationErrors());
        if (addAnotherFormValid) {
          this.handleAddAnotherSubmit(this.addAnotherFormValues);
          // this.$formulate.submit("add-another-rate");
        }
      }

      const rates = [...this.formValues.rates];
      for (const rate of rates) {
        rate.min = Number(rate.min);
        rate.max = Number(rate.max);
        rate.value = Number(rate.value);
      }

      await this.$axios
        .post("/promoters/commissions/" + this.url, {
          promoters: this.formValues.promoters,
          rates
        })
        .then(response => {
          console.log(response);
          this.$toast("Promoter commissions updated!", { type: "success" });

          this.$refs.table.refetch();
          this.formValues = {
            rates: [
              { min: "0.00", max: "4.99", value: "" },
              { min: "5.00", max: "9.99", value: "" },
              { min: "10.00", max: "19.99", value: "" },
              { min: "20.00", max: "29.99", value: "" },
              { min: "30.00", max: "39.99", value: "" },
              { min: "40.00", max: "49.99", value: "" }
            ],
            promoters: []
          };

          this.editingItem = {};
          this.editingIndex = -1;

          if (addAnotherFormValid) {
            this.$formulate.reset("add-another-rate");
          }
        });
    },
    submitAddAnother() {
      if (!this.showAddAnotherForm) {
        this.showAddAnotherForm = true;
      } else {
        this.$formulate.submit("add-another-rate");
      }
    },
    async handleAddAnotherSubmit(values) {
      console.log("submitted");
      this.formValues.rates.push(values);
      this.$formulate.reset("add-another-rate");
    }
  },
  mounted() {
    // TODO: seperate endpoint for all promoters
    this.$axios
      .post("/promoters/get-promoters?pageSize=100", {
        event: this.url
      })
      .then(response => {
        const data = response.data;

        this.promoters = data.data.items;

        console.log(data);
      });
  }
};
</script>

<style lang="less" scoped>
.commission-form {
  scroll-margin-top: 150px;

  .promoters {
    &::v-deep .formulate-input-group {
      display: flex;
      flex-wrap: wrap;

      &-item {
        width: calc(50% - 16px);
        padding-right: 16px;
        margin-bottom: 8px;
      }
    }

    .select-all {
      margin: auto;
    }
  }

  .commissions {
    background-color: var(--content-light);
    padding: 16px;
    border-radius: 8px;

    .header {
      display: flex;
      margin-bottom: 12px;
      text-transform: uppercase;

      span {
        width: 50%;
      }
    }

    .commission {
      display: flex;

      .range {
        display: flex;
        width: 50%;
        align-items: center;
        margin-bottom: 4px;
      }

      .value {
        width: 50%;

        .formulate-input {
          margin-bottom: 4px;
        }
      }
    }

    .add-another-form {
      display: flex;
      margin-top: 8px;

      .min,
      .max {
        width: 25%;
        padding-right: 8px;
      }

      .value {
        width: 50%;
      }
    }

    .add-another {
      display: flex;
      justify-content: flex-end;
    }
  }

  .save-changes {
    width: 100%;
  }
  &::v-deep .formulate-input-element--submit {
    display: flex;
    margin-top: 16px;
    justify-content: center;
  }
}
</style>
