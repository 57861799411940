<template>
  <div class="rankings">
    <h2>Promoter Rankings</h2>
    <div class="row">
      <div class="col quarterx3 flex center">
        <loader site="planners" :active="loading" :message="loadingMessage" />
      </div>
    </div>
    <div class="row" v-if="!loading">
      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Items Sold</h4>
          <div class="table-headers">
            <span>Promoter</span>
            <span>Items Sold</span>
          </div>
          <Card>
            <div
              v-for="(promoter, index) in promoters.ticketsSold"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>

              <span class="number" v-else>{{ index + 1 }}.</span>
              <span class="name">
                <span>{{ promoter.name }}</span>
                <small>{{ promoter.email }}</small>
              </span>
              <span>{{ promoter.ticketsSold }}</span>
            </div>
            <div v-if="promoters.ticketsSold.length === 0" class="table-value">
              <span>N/A</span>
            </div>
          </Card>
        </div>
      </div>
      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Revenue</h4>
          <div class="table-headers">
            <span>Promoter</span>
            <span>Revenue</span>
          </div>
          <Card>
            <div
              v-for="(promoter, index) in promoters.revenue"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>

              <span class="number" v-else>{{ index + 1 }}.</span>
              <span class="name">
                <span>{{ promoter.name }}</span>
                <small>{{ promoter.email }}</small>
              </span>
              <span>${{ promoter.revenue }}</span>
            </div>

            <div v-if="promoters.revenue.length === 0" class="table-value">
              <span>N/A</span>
            </div>
          </Card>
        </div>
      </div>
      <div class="tickets-sold col third">
        <div class="table tickets-purchased">
          <h4>Promo Code Tickets</h4>
          <div class="table-headers">
            <span>Promoter</span>
            <span>Promo Code Tickets</span>
          </div>
          <Card>
            <div
              v-for="(promoter, index) in promoters.promoCodes"
              class="table-value"
              :key="index"
            >
              <span class="rank" v-if="index === 0">
                <img src="/img/icons/icon_GoldStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 1">
                <img src="/img/icons/icon_SilverStar.svg" alt="" />
              </span>
              <span class="rank" v-else-if="index === 2">
                <img src="/img/icons/icon_BronzeStar.svg" alt="" />
              </span>

              <span class="number" v-else>{{ index + 1 }}.</span>
              <span class="name">
                <span>{{ promoter.name }}</span>
                <small>{{ promoter.email }}</small>
              </span>
              <span>{{ promoter.promoCodes }}</span>
            </div>
            <div v-if="promoters.promoCodes.length === 0" class="table-value">
              <span>N/A</span>
            </div>
          </Card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader.vue";

export default {
  name: "promoter-rankings",
  components: {
    Loader
  },
  props: {
    url: String
  },
  data() {
    return {
      loading: true,
      messages: [
        "Hang on tight, friend! Our servers are on a data-fetching spree, working like caffeinated squirrels. Stay tuned, your data is just around the corner. Enjoy the moment and keep those smiles shining!",
        "Hold onto your hats! Our data minions are diligently searching high and low to bring you the information you desire. Meanwhile, take a deep breath and remember: Patience is a virtue, and good things come to those who refresh!",
        "Rumor has it that our servers are doing the Cha-Cha Slide while fetching your data. So grab a cup of coffee, sit back, and enjoy the show. We promise your wait will be worth it!",
        "While you wait, let's play a game of 'Spot the Loading Spinner.' Keep your eyes peeled and see if you can catch it in action. Spoiler alert: It's quick! We'll have your data dancing onto your screen in no time!",
        "Just a heads up: Our data sprinters are currently doing their warm-up stretches before presenting your information. So sit tight, relax those typing fingers, and get ready for a grand performance!",
        "Waiting can be tough, but fear not! Our team of data whisperers is taming the wild information waves just for you. Take a deep breath, count to three, and visualize that data landing safely in your hands. It's coming!"
      ],
      loadingMessage: "",
      promoters: {
        ticketsSold: [],
        revenue: [],
        promoCodes: []
      }
    };
  },
  methods: {
    getRandomMessage() {
      const randomIndex = Math.floor(Math.random() * this.messages.length);
      this.loadingMessage = this.messages[randomIndex];
    }
  },

  created() {
    this.getRandomMessage();
    this.$axios
      .post("/promoters/planners/rankings", {
        event: this.url
      })
      .then(response => {
        this.$emit("set-title", response.data.title);

        const promoters = response.data.promoters;

        this.promoters.ticketsSold = promoters
          .sort((a, b) => b.tickets_sold - a.tickets_sold)
          .slice(0, 10)
          .map(p => ({
            name: p.name,
            email: p.email,
            ticketsSold: p.tickets_sold
          }));

        this.promoters.revenue = promoters
          .sort((a, b) => Number(b.revenue) - Number(a.revenue))
          .slice(0, 10)
          .map(p => ({
            name: p.name,
            email: p.email,
            revenue: p.revenue
          }));

        this.promoters.promoCodes = promoters
          .sort((a, b) => b.promo_codes - a.promo_codes)
          .slice(0, 10)
          .map(p => ({
            name: p.name,
            email: p.email,
            promoCodes: p.promo_codes
          }));
        setTimeout(() => {
          this.loading = false;
        }, 3000);
      })
      .catch(error => {
        this.loading = false;
      });
  }
};
</script>

<style lang="less" scoped>
#Content .content-inner .row .col.center {
  text-align: center;
}
.rankings {
  .tickets-sold {
    border-radius: 7px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .table {
      margin: 15px 0 15px 15px;
      padding-right: 15px;
      border-right: 1px solid #333b3f;

      &:last-of-type {
        border-right: none;
      }
    }

    @media screen and (max-width: 1080px) {
      flex-direction: column;

      .table {
        width: calc(100% - 30px);
        border-right: none;
      }
    }
  }

  .table {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 10px;

    h4 {
      margin: 0 0 10px 0;
      text-align: center;
    }

    .table-headers {
      display: flex;
      justify-content: space-between;
      padding: 0 16px;
      margin-bottom: 10px;
      // border-bottom: 1px solid #333b3f;
    }

    .table-value {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5px;

      .number {
        width: 40px;
        font-size: 20px;
      }
      .rank {
        margin-right: 8px;

        img {
          width: 18px;
        }
      }

      .name {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    }
  }
}
</style>