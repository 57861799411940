<template>
  <TTDialog title="Add Payment" :active="active" v-on:close="close">
    <FormulateForm
      name="add-payment"
      v-model="formValues"
      @submit="handleSubmit"
      autocomplete="false"
    >
      <FormulateInput
        type="text"
        label="Promoter"
        v-model="promoter.name"
        validation="required"
        disabled
        autocomplete="false"
      />
      <FormulateInput
        type="money"
        name="amount"
        label="Payment Amount ($)"
        placeholder="25.64"
        validation="required"
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="collectedBy"
        label="Paid By"
        placeholder="William Smithson"
        validation="required"
        autocomplete="false"
      />
      <FormulateInput
        type="select"
        name="source"
        label="Source of $"
        placeholder="Select Source of $"
        validation="required"
        :options="sources"
        autocomplete="false"
      />
      <FormulateInput
        type="text"
        name="notes"
        label="Payment Notes"
        placeholder="What was notable about this transaction?"
        autocomplete="false"
      />
      <FormulateInput type="submit" name="Save" />
    </FormulateForm>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";

export default {
  name: "add-payment-modal",
  components: {
    TTDialog
  },
  props: {
    event: String,
    promoters: Array
  },
  data() {
    return {
      active: false,
      promoter: null,
      sources: [
        { label: "Cash", value: "cash" },
        { label: "Cheque", value: "cheque" },
        { label: "Bank Transfer", value: "bank-transfer" },
        { label: "Credit Card", value: "credit-card" },
        { label: "Other", value: "other" }
      ],
      formValues: {
        promoter: "",
        amount: "",
        collectedBy: "",
        source: "",
        notes: ""
      }
    };
  },
  methods: {
    open(promoter) {
      this.promoter = promoter;
      this.formValues.amount = promoter.owing;

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      setTimeout(() => {
        // this.$refs.modal.removeAttribute('style')
        document.body.style.position = "relative";
      }, 500);
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    async handleSubmit() {
      console.log(this.formValues);

      this.$loader.start();
      this.close();

      const payment = {
        promoter_id: this.promoter._id,
        amount: this.formValues.amount,
        collected_by: this.formValues.collectedBy,
        source: this.formValues.source,
        notes: this.formValues.notes
      };

      this.$axios
        .post(`/promoters/commissions/${this.event}/payments`, {
          payment
        })
        .then(response => {
          this.$emit("added", response.data.payment);
          this.$loader.stop();
        });
    }
  }
};
</script>
