<template>
  <TTDialog title="Commission Rates" :active="active" v-on:close="close">
    <h3 style="margin-top: 0">{{ promoter.name }}</h3>
    <div class="rates">
      <div class="rate">
        <span>Ticket / Product Price</span>
        <span>Commission</span>
      </div>
      <div
        v-for="(rate, index) in promoter.commission_rates"
        class="rate"
        :key="index"
      >
        <span>{{ rate.min }} - {{ rate.max }}</span>
        <span>{{ rate.value }}</span>
      </div>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";

export default {
  name: "view-commission-modal",
  components: {
    TTDialog
  },
  props: {
    event: String,
    promoters: Array
  },
  data() {
    return {
      active: false,
      promoter: null,
      sources: [
        { label: "Cash", value: "cash" },
        { label: "Cheque", value: "cheque" },
        { label: "Bank Transfer", value: "bank-transfer" },
        { label: "Credit Card", value: "credit-card" },
        { label: "Other", value: "other" }
      ],
      formValues: {
        promoter: "",
        amount: "",
        collectedBy: "",
        source: "",
        notes: ""
      }
    };
  },
  methods: {
    open(promoter) {
      this.promoter = promoter;
      console.log("from modal", promoter);

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    close() {
      this.active = false;
      setTimeout(() => {
        // this.$refs.modal.removeAttribute('style')
        document.body.style.position = "relative";
      }, 500);
    },
    okay() {
      if (this.onConfirm) this.onConfirm();
      this.close();
    },
    onSelectPromoter(promoter) {
      this.formValues.promoter = promoter;
      this.validateAt("promoter");
    },
    async handleSubmit() {
      console.log({
        promoter_id: this.promoter,
        collection_date: Date.now(),
        amount: this.amount,
        collected_by: this.collectedBy,
        source: this.source,
        notes: this.notes
      });

      this.$loader.start();
      this.close();

      const payment = {
        promoter_id: this.formValues.promoter,
        amount: this.formValues.amount,
        collected_by: this.formValues.collectedBy,
        source: this.formValues.source,
        notes: this.formValues.notes
      };

      this.$axios
        .post("/promoters/add-comm-payment", {
          event: this.event,
          payment
        })
        .then(response => {
          this.$emit("added", response.data.payment);
          this.$loader.stop();
        });
    }
  }
};
</script>

<style lang="less" scoped>
.rates {
  padding: 16px;
  background-color: var(--content-dark);
  border-radius: 8px;

  .rate {
    display: flex;

    span {
      width: 50%;
    }

    &:nth-child(1) {
      text-transform: uppercase;
    }

    &:nth-child(2) {
      margin-top: 8px;
    }
  }
}
</style>
