<template>
  <TTDialog
    :active="active"
    v-on:close="close"
    :width="700"
    :title="'Promoter Info: ' + order.name"
  >
    <!-- <h3 style="margin-top: 0">{{ order.name }}</h3> -->
    <div class="rates">
      <div class="rate">
        <span>Tickets Sold</span>
        <span>Registrations</span>
        <span>Refunded Count</span>
        <span>Grand Total</span>
      </div>
      <div class="rate">
        <span>{{ order.tickets_sold }}</span>
        <span>{{ order.registrations }}</span>
        <span>{{ order.refunded }}</span>
        <span>${{ order.grand_total }}</span>
      </div>
    </div>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";

export default {
  name: "order-details",
  components: {
    TTDialog
  },
  props: {
    isReservedSeatsType: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      active: false,
      order: null,
      tab: 0,
      edits: false,
      refund: false,
      saveButton: false,
      newPromoter: "",
      newPromoterValue: ""
    };
  },
  computed: {
    saveBtnTxt() {
      if (this.edits) {
        return "Save";
      } else {
        return "Edit Order";
      }
    },
    tabs() {
      return [
        "ORDER DETAILS",
        "TICKET DETAILS",
        this.order && this.order.custom_fields && "Q&A",
        "ADDITIONAL DETAILS"
      ].filter(Boolean);
    }
  },
  methods: {
    open(order) {
      this.order = order;

      setTimeout(() => {
        this.active = true;
      }, 50);
    },
    close() {
      this.active = false;
    },
    hasValue(item, column) {
      return item[column.toLowerCase()] !== "undefined";
    },
    openRefundModal() {
      this.$refs.refundModal.open(this.order);
    },
    updatePromoterLocal(input) {
      this.newPromoterValue = input;
      this.newPromoter = input.value;
      this.saveButton = true;
    },
    updatePromoterEvent() {
      let newPromoter = this.newPromoter;
      this.newPromoter = "";
      this.newPromoterValue = "";
      this.$emit("update-promoter", newPromoter);
    },
    hasValueNested(obj, key) {
      return key.split(".").every(function(x) {
        if (typeof obj != "object" || obj === null || !x in obj) return false;
        obj = obj[x];
        return true;
      });
    },
    saveInfo() {
      this.$emit("editOrder", this.order);
      this.edits = false;
      this.close();
    },
    issueRefund() {
      this.$emit("issueRefund", this.order);
      this.refund = false;
      this.close();
      // alert("Refund");
    }
  }
};
</script>

<style lang="less" scoped>
.detail {
  display: flex;
  flex-direction: column;
}
.rates {
  padding: 16px;
  background-color: var(--content-dark);
  border-radius: 8px;

  .rate {
    display: flex;

    span {
      width: 50%;
    }

    &:nth-child(1) {
      text-transform: uppercase;
    }

    &:nth-child(2) {
      margin-top: 8px;
    }
  }
}
</style>
