<template>
  <div class="dashboard">
    <promoter-info ref="detailsModal" />
    <h2>Manage Promoters</h2>
    <InfoText>
      <h3 style="text-align:center; color: var(--text)">
        <strong>
          Helpful Tips
        </strong>
      </h3>
      <p>
        After adding a promoter take a look at the table below and ensure that
        you have allocated the correct number of tickets to sell under "Tix
        Left". You can edit this number at anytime.
      </p>
      <p>
        If you don't want a promoter to sell tickets at any point you can
        deselect the check under Active.
      </p>
      <p>Don't forget to set commissions!</p>
    </InfoText>
    <FormulateForm
      name="promoter"
      v-model="formValues"
      class="promoter-info"
      @submit="handleSubmit"
      autocomplete="false"
    >
      <div class="row">
        <div class="col half">
          <FormulateInput
            type="text"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            validation="required"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="text"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            validation="required"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="text"
            name="email"
            label="Email"
            placeholder="example@domain.com"
            :validation="formulateEmailValidation"
            :validation-messages="formulateEmailValidationMessage"
            v-on:blur="onBlurEmailInput"
            autocomplete="false"
          />
        </div>
        <div class="col half">
          <FormulateInput
            type="tel"
            name="phone"
            label="Phone Number"
            placeholder="(123) 456-7890"
            validation="max:20,length"
            autocomplete="false"
          />
        </div>

        <div class="col half">
          <FormulateInput
            type="checkbox"
            name="isSalesPerson"
            label="Is Sales Person?"
            autocomplete="false"
          />

          <!-- <FormulateInput
            type="multiSelect"
            name="promoterTags"
            label="Promoter Tags"
            placeholder="Select Tags"
            validation="required"
            autocomplete="false"
            :options="promoterTags"
          /> -->
        </div>
        <!-- TODO: cash sales -->
        <div v-if="false" class="col half">
          <FormulateInput
            id="ticket-count"
            type="number"
            name="tickets"
            label="How Many Tickets Can The Promoter Sell?"
            placeholder="Enter number of tickets"
            validation="required"
            autocomplete="false"
          />
        </div>
      </div>
      <div class="promoter-info-submit">
        <div class="promoter-info-submit-wrapper">
          <FormulateInput type="submit" name="Submit" />
        </div>
      </div>
    </FormulateForm>
    <div class="text-divider">
      <hr />
      <p>OR</p>
      <hr />
    </div>

    <div class="previous-promoters">
      <div class="sub-title">
        <h4>
          Add previous promoters.
        </h4>
        <span style="color: var(&#45;&#45;text)">
          &nbsp; Note: You will be able to adjust available tickets and other
          options below
        </span>
      </div>

      <div class="promoter-search-wrapper">
        <div class="promoter-search-input">
          <FormulateInput
            type="multiSelect"
            label="Add Previous Promoters"
            placeholder="Select Previous Promoters"
            use-count-label
            :options="prevPromoters"
            v-model="prevPromoterSelection"
            autocomplete="false"
          />
        </div>
        <div class="promoter-search-button">
          <div class="promoter-search-button-wrapper">
            <diyobo-button
              txt="Submit"
              class="primary"
              v-on:click="onSubmitPrevPromoters"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col flex center">
          <diyobo-button
            small
            class="select-all-permissions"
            type="primary"
            :txt="allPrevSelected ? 'Deselect All' : 'Select All'"
            @click="onSelectAll()"
          />
        </div>
      </div>
    </div>
    <!-- <hr class="divider" /> -->
    <!-- <Card> -->
    <section>
      <DataTable
        title="Promoters Added"
        endpoint="/promoters/:event/list"
        compact
        :args="{ event: url }"
        ref="table"
        :headers="headers"
        :custom-columns="[
          'accepts_cash',
          'link',
          'userLink',
          'tickets_left',
          'active',
          'status',
          'name',
          'details',
          'is_salesperson'
        ]"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div class="name-tag">
            <div class="green-dot" v-if="item.status == 'Yes'"></div>
            <div class="red-dot" v-else></div>
          </div>
        </template>
        <template v-slot:[`item.is_salesperson`]="{ item }">
          <div class="name-tag">
            <div class="green-dot" v-if="item.is_salesperson"></div>
            <div class="red-dot" v-else></div>
          </div>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span
            v-if="item.status == 'Yes'"
            @click="openEvent(item.event_link)"
            style="cursor: pointer"
          >
            {{ item.name }}
          </span>
          <span v-else>{{ item.name }}</span>
        </template>
        <template v-slot:[`item.accepts_cash`]="{ item }">
          <FormulateInput
            type="checkbox"
            disabled
            ignored
            v-model="item.accepts_cash"
            @input="updatePromoter(item, 'accepts_cash', $event)"
            autocomplete="false"
          />
        </template>
        <template v-slot:[`item.link`]="{ item }">
          <div class="link-container">
            <FormulateInput
              type="checkbox"
              ignored
              v-model="item.link"
              @input="updatePromoter(item, 'link', $event)"
              autocomplete="false"
            />
            <div class="icon-wrapper">
              <font-awesome-icon
                class="icon"
                @click="copyPromoterLink(item)"
                icon="link"
              />
              <span class="icon-tool-tip">Copy Promoter's Link</span>
            </div>
          </div>
        </template>
        <template v-slot:[`item.tickets_left`]="{ item }">
          <FormulateInput
            type="text"
            small
            ignored
            v-model="item.tickets_left"
            @input="updatePromoter(item, 'tickets_left', $event)"
            autocomplete="false"
          />
        </template>
        <template v-slot:[`item.active`]="{ item }">
          <FormulateInput
            type="checkbox"
            :disabled="isNmp && item.removed"
            ignored
            v-model="item.active"
            @input="updatePromoter(item, 'active', $event)"
            autocomplete="false"
          />
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-icon @click="details(item)">mdi-information-outline</v-icon>
        </template>
      </DataTable>
      <div
        style="display: flex; justify-content: center; flex-direction: column"
      >
        <h2>Done adding promoters?</h2>
        <DiyoboButton
          type="primary"
          txt="Set Commission Rates"
          @click="$router.push('./commissions')"
        />
      </div>
    </section>
    <!-- </Card> -->
  </div>
</template>

<script>
import DiyoboButton from "@/components/DiyoboButton.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEdit, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import dateFormat from "dateformat";
import {
  faAngleDown,
  faCalendarAlt,
  faClock,
  faLink
} from "@fortawesome/free-solid-svg-icons";
import ModalContent from "@/helpers/modals";
import TabTote from "@/components/TabTote.vue";
import {
  formulateEmailValidation,
  formulateEmailValidationMessage
} from "@/helpers/email";
import DataTable from "@/components/DataTable.vue";
import { saveToClipboard } from "@/helpers/clipboard";
import InfoText from "@/components/InfoText.vue";
import PromoterInfo from "../../components/modals/PromoterInfo.vue";

library.add(faAngleDown, faCalendarAlt, faClock, faEdit, faTimesCircle, faLink);

export default {
  name: "promoter-dashboard",
  components: {
    DiyoboButton,
    FontAwesomeIcon,
    TabTote,
    DataTable,
    InfoText,
    PromoterInfo
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Promoter Dashboard"
    };
  },
  data() {
    return {
      promoters: [],
      prevPromoters: [],
      title: "",
      headers: [
        { text: "", value: "status", sortable: false },
        { text: "Full Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Phone Number", value: "phone", sortable: false },
        // { text: "Cash?", value: "accepts_cash", sortable: false },
        { text: "Link?", value: "link", sortable: false },
        // { text: "Tix Sold", value: "tickets_sold", sortable: true },
        // {text: "Refunded", value: "refunded", sortable: false},
        // {text: "Grand total", value: "grand_total", sortable: false},
        { text: "Allocated", value: "tickets_left", sortable: false },
        { text: "Sale agent", value: "is_salesperson", sortable: false },
        {
          text: "Date Added",
          value: "created",
          format: "date",
          sortable: false
        },
        { text: "Active", value: "active", sortable: false },
        { text: "Details", value: "details", sortable: false }
      ],
      tableData: {},
      prevPromoterSelection: [],
      formValues: {
        email: "",
        firstName: "",
        lastName: "",
        phone: "",
        tickets: "",
        acceptsCash: false,
        hasLink: true
      },
      allPrevSelectedVal: false,
      detailsItem: null,
      promoterTags: [{ label: "Sales", value: "sales" }]
    };
  },
  computed: {
    formulateEmailValidation,
    formulateEmailValidationMessage,
    allPrevSelected: {
      get: function() {
        if (this.prevPromoterSelection.length === this.prevPromoters.length) {
          return true;
        }
        return this.allPrevSelectedVal;
      },

      set: function(newValue) {
        this.allPrevSelectedVal = newValue;
      }
    },
    isNmp() {
      return this.$store.state.user.activeBrand.subdomain == "nmp";
    }
  },
  methods: {
    openEvent(link) {
      window.open(link, "_blank");
    },
    copyPromoterLink(item) {
      if (
        this.$store.state.user.activeBrand.subdomain == "nmp" &&
        item.status == "No"
      ) {
        return;
      }
      saveToClipboard(item.event_link);
      this.$toast("Promoter linked copied!", { type: "success" });
    },
    async handleSubmit() {
      this.$loader.start();
      const email = this.formValues.email;

      if (this.promoters.some(p => p.email === email)) {
        this.$store.state.bus.$emit("alert", ModalContent.sellerAlreadyAdded);
        this.$loader.stop();
        return;
      }

      const promoterObj = {
        email: this.formValues.email,
        name: this.formValues.firstName + " " + this.formValues.lastName,
        firstName: this.formValues.firstName,
        lastName: this.formValues.lastName,
        phone: this.formValues.phone,
        isSalesPerson: this.formValues.isSalesPerson,
        // TODO: cash sales
        // accepts_cash: this.formValues.acceptsCash,
        // link: this.formValues.hasLink,
        accepts_cash: false,
        link: true,
        created: Date.now(),
        tickets_sold: 0,
        // TODO: cash sales
        tickets_left: 10000,
        active: true
      };

      await this.$axios
        .post("/promoters/add-promoter", {
          promoter: promoterObj,
          event: this.url
        })
        .then(response => {
          if (response.data.revert) {
            this.promoters.forEach(promoter => {
              if (promoter.email == promoterObj.email) {
                promoter.active = true;
              }
            });
            this.$store.state.bus.$emit(
              "alert",
              ModalContent.sellerUnacrchived
            );
          } else {
            promoterObj.id = response.data.id.$oid;
            promoterObj.created_display = dateFormat(
              promoterObj.created,
              "ddd, mmm dS, yyyy"
            );

            this.promoters.push(promoterObj);
            this.prevPromoters = this.prevPromoters.filter(
              p => p.value !== promoterObj.id
            );
            this.$store.state.bus.$emit("alert", ModalContent.sellerAdded);
          }
          this.$refs.table.refetch();
          this.$formulate.reset("promoter");
          this.$loader.stop();
        })
        .catch(error => {
          this.$loader.stop();

          if (error.response) {
            let errorData = error.response.data;
            if (errorData) {
              this.$store.state.bus.$emit(
                "alert",
                ModalContent.sellerAlreadyAdded
              );

              // this.$bus.$emit("alert", {
              //   msg: errorData.message
              // });
            } else {
              console.log(error.response);
            }
          }
        });
    },
    updatePromoter(promoter, field) {
      if (promoter[field] === "") {
        return;
      }

      if (
        this.$store.state.user.activeBrand.subdomain == "nmp" &&
        field == "active" &&
        !promoter[field]
      ) {
        this.$bus.$emit("alert", {
          msg:
            "Select Disable to remove this promoter from the event or Archive to remove the promoter from the system entirely.",
          confirm: "Archive",
          cancelBtn: "Disable",
          closable: false,
          onConfirm: () => {
            this.$axios.post("/planners/remove-promoter", {
              event: this.url,
              promoter_id: promoter.id
            });
            promoter.removed = true;
            this.$forceUpdate();
          },
          onClose: () => {
            this.$axios.post("/promoters/update-promoter", {
              event: this.url,
              promoter: {
                id: promoter.id,
                field,
                value: promoter[field]
              }
            });
          }
        });
      } else {
        this.$axios.post("/promoters/update-promoter", {
          event: this.url,
          promoter: {
            id: promoter.id,
            field,
            value: promoter[field]
          }
        });
      }
    },
    onSubmitPrevPromoters() {
      const selected = this.prevPromoterSelection;
      const maskEl = document.querySelector(
        ".formulate-input-element .mask.active"
      );
      if (maskEl) {
        maskEl.click();
      }

      if (selected.length > 0) {
        this.$loader.start();

        this.$axios
          .post("/promoters/add-prev-promoters", {
            event: this.url,
            promoters: selected
          })
          .then(response => {
            // const newPromoters = data.promoters.map(v => {
            //   v.created_display = dateFormat(
            //     new Date(+v.created_at.$date.$numberLong),
            //     "ddd, mmm dS, yyyy"
            //   );
            //   return v;
            // });

            // this.promoters.push(...newPromoters);
            this.prevPromoters = this.prevPromoters.filter(
              p => !selected.includes(p.value)
            );
            this.prevPromoterSelection = [];

            this.$store.state.bus.$emit(
              "alert",
              ModalContent.sellerAddedPrevious
            );

            this.$refs.table.refetch();

            this.$loader.stop();
            // window.location.reload();
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    onBlurEmailInput() {
      if (this.formValues.email) {
        if (this.promoters.some(p => p.email === this.formValues.email)) {
          this.formErrors.email =
            "There is already a promoter with this email.";
          return;
        }

        this.$axios
          .post("/promoters/suggest-promoter", {
            email: this.formValues.email,
            event: this.url
          })
          .then(response => {
            const data = response.data;

            if (data.promoter) {
              const promoter = data.promoter;
              const content = ModalContent.sellerFound;

              this.$store.state.bus.$emit("alert", {
                title: content.title,
                msg: content.msg.replace("{promoter}", promoter.name),
                confirm: content.confirm,
                onConfirm: () => {
                  this.formValues.email = promoter.email;
                  this.formValues.firstName = promoter.name.split(" ")[0];
                  this.formValues.lastName = promoter.name.split(" ")[1];
                  this.formValues.phone = promoter.phone;
                  this.formValues.tickets = promoter.tickets_left;
                  this.formValues.acceptsCash = promoter.accepts_cash;
                  this.formValues.hasLink = promoter.link;
                }
              });
            }
          });
      }
    },
    onSelectAll() {
      let allPrevSelected = !this.allPrevSelected;
      this.allPrevSelected = allPrevSelected;
      // this.allPrevSelected = !this.allPrevSelected;
      if (allPrevSelected) {
        this.prevPromoterSelection = this.prevPromoters.map(p => p.value);
      } else {
        this.prevPromoterSelection = [];
      }
    },
    async details(item) {
      // this.detailsIndex = this.data.indexOf(item);
      this.detailsItem = Object.assign({}, item);
      let totals = await this.getPromoterInfo(item);

      item.tickets_sold = await totals.tickets_sold;
      item.registrations = await totals.registrations;
      item.refunded = await totals.refunded;
      item.grand_total = await totals.grand_total;

      // console.log(item);
      this.$refs.detailsModal.open(item);
    },
    async getPromoterInfo(promoter) {
      const { data } = await this.$axios.post(`/promoters/get-promoter-info`, {
        event: this.url,
        promoter: promoter._id
      });

      return data;
    }
  },

  created() {
    if (this.$store.state.user.activeBrand.subdomain !== "nmp") {
      this.headers = this.headers.filter(item => item.value !== "status");
    }
    if (this.$route.params.url === "wake-up-call-49974589893") {
      this.headers = this.headers.filter(
        item => item.value !== "refunded" && item.value !== "grand_total"
      );
      this.$forceUpdate();
    }
  },

  mounted() {
    this.$axios
      .post("/promoters/get-prev-promoters", {
        event: this.url
      })
      .then(response => {
        const data = response.data;

        data.promoters.sort((a, b) => a.name.localeCompare(b.name));

        this.prevPromoters = data.promoters.map(p => ({
          label: p.name + ` (${p.email})`,
          value: p.id
        }));

        // people.sort((a, b) => a.name.localeCompare(b.name));
      });
  }
};
</script>

<style lang="less" scoped>
.name-tag {
  display: flex;
  align-items: center;
  justify-content: start;

  .red-dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    background: red;
    border-radius: 50%;
    margin-right: 6px;
  }

  .green-dot {
    cursor: pointer;
    height: 8px;
    width: 8px;
    background: green;
    border-radius: 50%;
    margin-right: 6px;
  }
}

.dashboard {
  .page-tabs {
    padding: 20px 0;
  }

  .promoter-info {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;

    .promoter-info-submit {
      width: 100%;

      &::v-deep button {
        display: flex;
        justify-content: center;
        margin: 20px auto;
      }

      // end promoter-info-submit-wrapper
    }

    // end promoter-info-submit
  }

  // end promoter-info

  .text-divider {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    color: #999999;
    height: 20px;
    justify-content: space-between;

    p {
      position: relative;
      top: -20px;
    }

    hr {
      width: 46%;
      height: 0.1px;
    }
  }

  // end text-divider

  .previous-promoters {
    .sub-title {
      text-align: center;
      margin: 16px 0;

      span {
        font-size: 14px;
        color: var(--gray-9);
      }

      // display: flex;
      // flex-wrap: wrap;
      // justify-content: center;

      h4 {
        margin: 0;
        text-transform: uppercase;
        // font-weight: normal;
      }
    }

    //end sub-title

    .promoter-search-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .promoter-search-input {
        width: 55%;

        @media screen and (max-width: 555px) {
          width: 100%;
          padding-bottom: 10px;
        }
      }

      // end promoter-search-input

      .promoter-search-button {
        position: relative;

        .promoter-search-button-wrapper {
          position: absolute;
          margin-left: 7px;
          top: 30px;

          @media screen and (max-width: 555px) {
            position: static;
            top: -15px;
          }
        }
      }

      // end promoter-search-button
    }
  }

  // end previous-promoters

  .link-container {
    display: flex;
    align-items: center;

    .icon-wrapper {
      position: relative;
      cursor: pointer;

      .icon {
        color: var(--primary-green);
        font-size: small;
      }

      .icon-tool-tip {
        position: absolute;
        left: 20px;
        top: 10px;
        width: 130px;
        display: none;
      }
    }

    .icon-wrapper:hover {
      .icon-tool-tip {
        display: inline;
      }
    }
  }
}
</style>
