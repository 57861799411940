<template>
  <div class="financials">
    <FormulateInput
      type="select"
      label="Promoter"
      placeholder="Select Promoter"
      :options="promotersForDropdown"
      @input="onSelectPromoter"
    />
    <template v-if="promoter">
      <TabTote :tabs="navTabs" v-model="navTabSelected" />
      <template v-if="buyerOrPlannerPaysFees === 'buyerPaysFees'">
        <DataTable
          v-if="navTabSelected === 0"
          :key="0"
          title="Ticket Sales"
          endpoint="/planners/financials/:event/calculateTicketSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[0]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'plannerPaysFees'">
        <DataTable
          v-if="navTabSelected === 0"
          :key="0"
          title="Ticket Sales"
          endpoint="/planners/financials/:event/calculateTicketSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[1]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'buyerPaysFees'">
        <DataTable
          v-if="navTabSelected === 1"
          :key="1"
          title="Product Sales"
          endpoint="/planners/financials/:event/calculateProductSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[2]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'plannerPaysFees'">
        <DataTable
          v-if="navTabSelected === 1"
          :key="1"
          title="Product Sales"
          endpoint="/planners/financials/:event/calculateProductSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[3]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'buyerPaysFees'">
        <DataTable
          v-if="navTabSelected === 2"
          :key="2"
          title="Discounts"
          endpoint="/planners/financials/:event/calculateDiscountSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[4]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
      <template v-if="buyerOrPlannerPaysFees === 'plannerPaysFees'">
        <DataTable
          v-if="navTabSelected === 2"
          :key="2"
          title="Discounts"
          endpoint="/planners/financials/:event/calculateDiscountSales"
          :args="{ event: url }"
          :params="{ pv: promoter }"
          ref="table"
          :headers="headers[5]"
          :useSearchAndPagination="false"
        ></DataTable>
      </template>
    </template>
  </div>
</template>

<script>
import DiyoboInput from "@/components/DiyoboInput.vue";
import DiyoboButton from "@/components/DiyoboButton.vue";
import AddPaymentModal from "../../components/modals/AddPaymentModal.vue";
import ViewCommissionModal from "../../components/modals/ViewCommissionModal.vue";
import DataTable from "@/components/DataTable.vue";
import Loader from "@/components/Loader.vue";
import TinyButton from "@/components/TinyButton.vue";
import TabTote from "@/components/TabTote.vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faMoneyBill,
  faLink,
  faHandHoldingUsd,
  faTicketAlt
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faMoneyBill, faLink, faHandHoldingUsd, faTicketAlt);

export default {
  name: "promoter-financials",
  components: {
    DiyoboInput,
    DiyoboButton,
    DataTable,
    TinyButton,
    TabTote,
    AddPaymentModal,
    ViewCommissionModal,
    Loader,
    FontAwesomeIcon
  },
  props: {
    url: String
  },
  head() {
    return {
      title: "Promoter Financials"
    };
  },
  data() {
    return {
      headers: [
        // Ticket Sales BPFT
        [
          { text: "", value: "name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Ticket Price",
            value: "ticket_price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Add Fee",
            value: "add_fee",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ],
        // Ticket Sales PPFT
        [
          { text: "", value: "name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Ticket Price",
            value: "ticket_price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Add Fee",
            value: "add_fee",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "T-Fee", value: "dfee", sortable: true, format: "currency" },
          { text: "-", value: "", sortable: false },
          {
            text: "TT Tax",
            value: "tt_taxes",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ],
        // Product Sales BPFT
        [
          { text: "", value: "product_name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Product Price",
            value: "product_price",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ],
        // Product Sales PPFT
        [
          { text: "", value: "product_name", sortable: true },
          { text: "Sold", value: "sold", sortable: true },
          {
            text: "Product Price",
            value: "product_price",
            sortable: false,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "T-Fee", value: "dfee", sortable: true, format: "currency" },
          { text: "-", value: "", sortable: false },
          {
            text: "TT Tax",
            value: "tt_taxes",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ],
        // Discounts BPFT
        [
          { text: "", value: "item_name", sortable: false },
          { text: "Sold", value: "sold", sortable: false },
          {
            text: "# of Tickets",
            value: "num_of_tickets",
            sortable: false
          },
          {
            text: "# of Products",
            value: "num_of_products",
            sortable: false
          },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Discount",
            value: "price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ],
        // Discounts PPFT
        [
          { text: "", value: "item_name", sortable: false },
          { text: "Sold", value: "sold", sortable: false },
          {
            text: "# of Tickets",
            value: "num_of_tickets",
            sortable: false
          },
          {
            text: "# of Products",
            value: "num_of_products",
            sortable: false
          },
          {
            text: "Sub Total",
            value: "sub_total",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Discount",
            value: "price",
            sortable: false,
            format: "currency"
          },
          { text: "+", value: "", sortable: false },
          {
            text: "Planner Tax",
            value: "planner_tax",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          { text: "T-Fee", value: "t_fee", sortable: true, format: "currency" },
          { text: "-", value: "", sortable: false },
          {
            text: "TT Tax",
            value: "tt_taxes",
            sortable: true,
            format: "currency"
          },
          { text: "-", value: "", sortable: false },
          {
            text: "Commission",
            value: "pv_commission",
            sortable: true,
            format: "currency"
          },
          { text: "=", value: "", sortable: false },
          { text: "Total", value: "total", format: "currency" }
        ]
      ],
      buyerOrPlannerPaysFees: "",
      navTabs: ["Ticket Sales", "Product Sales", "Discounts"],
      navTabSelected: 0,
      editingItem: {},
      editingIndex: -1,
      promoters: null,
      promoter: null,
      loading: false
    };
  },
  computed: {
    promotersForDropdown() {
      return this.promoters
        ? this.promoters.map(p => ({
            label: p.name,
            value: p.id
          }))
        : [];
    }
  },
  methods: {
    validateOverlap({ value }) {
      return !this.formValues.rates.some(
        r => Number(r.min) <= Number(value) && Number(r.max) >= Number(value)
      );
    },
    validateGreater({ value }) {
      return Number(value) > Number(this.addAnotherFormValues.min);
    },
    onAddedPayment(payment) {
      this.$toast("Promoter collection submitted!", { type: "success" });
      this.$refs.table.refetch();
    },
    onViewCommissions(promoter) {
      console.log(promoter);
      this.$refs.viewCommissionModal.open(promoter);
    },
    onRecordPayment(promoter) {
      console.log(promoter);
      this.$refs.addPaymentModal.open(promoter);
    },
    onDetails(promoter, index) {
      console.log(promoter);
      this.editingItem = promoter;
      this.editingIndex = index;
      this.formValues.promoters = [promoter.id];
      this.formValues.rates = promoter.commission_rates.map(r => ({
        ...r,
        value: Number(r.value).toFixed(2),
        disabled: true
      }));

      this.$el
        .querySelector(".commission-form")
        .scrollIntoView({ behavior: "smooth" });
    },
    onSelectAllPromoters() {
      const selected = this.allPromotersSelected;
      console.log("allpromoters", this.promotersForCheckboxes);

      if (selected) {
        this.formValues.promoters = [];
      } else {
        this.formValues.promoters = this.promotersForCheckboxes
          .filter(p => !p.disabled)
          .map(p => p.value);
      }
    },
    handlePromoterCheck(promoter, value) {
      const index = this.formValues.promoters.indexOf(promoter);
      if (value) {
        if (index === -1) {
          this.formValues.promoters.push(promoter);
        }
      } else {
        this.formValues.promoters.splice(index, 1);
      }
    },
    async handleSubmit() {
      const rates = [...this.formValues.rates];
      for (const rate of rates) {
        rate.min = Number(rate.min);
        rate.max = Number(rate.max);
        rate.value = Number(rate.value);
      }

      let addAnotherFormValid = false;
      if (this.$refs.addAnotherForm) {
        addAnotherFormValid = !(await this.$refs.addAnotherForm.hasValidationErrors());
        if (addAnotherFormValid) {
          this.$formulate.submit("add-another-rate");
        }
      }

      await this.$axios
        .post("/promoters/commissions/" + this.url, {
          promoters: this.formValues.promoters,
          rates
        })
        .then(response => {
          console.log(response);
          this.$toast("Promoter commissions updated!", { type: "success" });

          this.$refs.table.refetch();
          this.formValues = {
            rates: [
              { min: "0.00", max: "4.99", value: "" },
              { min: "5.00", max: "9.99", value: "" },
              { min: "10.00", max: "19.99", value: "" },
              { min: "20.00", max: "29.99", value: "" },
              { min: "30.00", max: "39.99", value: "" },
              { min: "40.00", max: "49.99", value: "" }
            ],
            promoters: []
          };

          this.editingItem = {};
          this.editingIndex = -1;

          if (addAnotherFormValid) {
            this.$formulate.reset("add-another-rate");
          }
        });
    },
    submitAddAnother() {
      if (!this.showAddAnotherForm) {
        this.showAddAnotherForm = true;
      } else {
        this.$formulate.submit("add-another-rate");
      }
    },
    onSelectPromoter(promoter) {
      this.promoter = promoter;
      if (this.$refs.table) {
        this.$refs.table.refetch();
      }
    }
  },
  mounted() {
    // TODO: seperate endpoint for all promoters
    this.$axios
      .post("/promoters/get-promoters?pageSize=100", {
        event: this.url
      })
      .then(response => {
        const data = response.data;

        this.promoters = data.data.items;
        this.buyerOrPlannerPaysFees = data.data.event.buyerOrPlannerPaysFees;

        console.log(data);
      });
  }
};
</script>
